import React from 'react';
import Cors from '../components/Navbar/Cors';

import instagram from "../asset/instagram.svg";
import facebook from "../asset/facebook.svg";

import enpcEdiser from "../asset/enpc-ediser.png";

export default function Accueil () {

    const textIntro = `Sylvain met à votre service son savoir-faire et ses 15 années d'expérience pour vous former à la conduite automobile et la sécurité routière. Il est à l'écoute de vos besoins, de vos difficultés et assure un accompagnement personnalisé tout au long de votre formation. Bénéficiez de son professionnalisme pour adopter une conduite sûre et responsable.`;

    const textPermisMotoPiste = "Piste privée à 5 minutes de l'auto école pour les formations motos.";

    const coordonnees = [
        {
            label: "Adresse",
            text: "Place du Donjon, 78550 Houdan"
        },
        {
            label: "Télephone",
            text: "09 81 65 94 16"
        },
        {
            label: "Email",
            text: "sysyautoecole@gmail.com"
        }
    ];

    const resaux = [
        {
            // Instagram
            name: "sysy_autoecole_",
            image: instagram,
            link: "https://www.instagram.com/sysy_automotoecole_/"
        },
        {
            // Facebook
            name: "sysy_autoecole_",
            image: facebook,
            link: "https://www.facebook.com/sysyautoecole"
        },/*
        {
            // Tiktok
            name: "@sysy_auto_ecole",
            image: tiktok,
            link: "https://www.tiktok.com/@sysy_auto_ecole"
        },
        {
            // Snapchat
            name: "sysyautoecole",
            image: snapchat,
            link: ""
        }*/
    ];

    return (
        <div className='background-sysy'>
            <Cors>
                <div className="bg-gray-200 rounded-xl ">
                    <p className="text-xl text-center py-4 leading-loose my-2 mx-4">
                        {textIntro}
                    </p>
                    <p className="text-red-500 text-base text-center font-bold leading-loose pb-4 mb-4 mx-4">
                        {textPermisMotoPiste.toUpperCase()}
                    </p>
                </div>
                <div className="lg:flex">
                    <div className="max-w-auto overflow-hidden shadow-lg pt-4 bg-gray-200 rounded-xl">
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl">Coordonnées</div>
                            {
                                coordonnees.map((data, key) => {
                                        return (
                                            <div key={key} className="py-2 px-2 md:flex">
                                                <span className="underline">{data.label}</span>
                                                <span className="pl-2">{data.text}</span>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">Réseaux sociaux</div>
                            <ul className="list-none">
                                {
                                    resaux.map((data, key) => {
                                            return (
                                                <li key={key} className="py-1 hover:bg-red-300 rounded hover:rounded-lg">
                                                    <a href={data.link} target="_blank" rel="noreferrer">
                                                           <span className="flex items-center">
                                                            <img className="w-12 h-12" src={data.image} alt={data.name}/>
                                                            <div className="text-left">{data.name}</div>
                                                        </span>
                                                    </a>
                                                </li>
                                            )
                                        }
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="relative flex-auto md:px-4">
                        <iframe
                            title="google-map"
                            className="h-full w-full aspect-auto" 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d638.4437268396292!2d1.5989178561937394!3d48.78940988007344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6a5d2a140534b%3A0xbb1f4bf955f4d370!2sSYSY-AUTO-MOTO-ECOLE!5e0!3m2!1sfr!2sfr!4v1665830895539!5m2!1sfr!2sfr" 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                    <div className=" overflow-hidden shadow-lg pt-4 bg-gray-200 rounded-xl">
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">Horaires heure de conduite</div>
                            <table className="table-auto border-separate [border-spacing:0.75rem]">
                                <tbody>
                                    <tr>
                                        <td colSpan="3">
                                            Les heures de conduite sont de 7h à 20h du lundi au samedi.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="font-bold text-xl mb-2">Horaires d'ouverture code et bureau </div>
                            <table className="table-auto border-separate [border-spacing:0.75rem]">
                                <tbody>
                                    <tr>
                                        <td className="underline">Lundi</td>
                                        <td>Fermé</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Mardi</td>
                                        <td>16:00</td>
                                        <td>19:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Mercredi</td>
                                        <td>16:00</td>
                                        <td>19:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Jeudi</td>
                                        <td>16:00</td>
                                        <td>19:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Vendredi</td>
                                        <td>16:00</td>
                                        <td>19:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Samedi</td>
                                        <td>10:00</td>
                                        <td>13:00</td>
                                    </tr>
                                    <tr>
                                        <td className="underline">Dimanche</td>
                                        <td>Fermé</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div> 
                <div className="bg-gray-200 rounded-xl py-4 px-4 mt-4">
                    <a href="https://www.enpc-ediser.com/" target="_blank" rel="noreferrer">
                        <img className="w-auto h-24 mx-auto pb-4" src={enpcEdiser} alt="enpc ediser"/>
                        <div className="pl-4">
                            <div className="font-bold text-xl mb-2 text-center">
                                Une pédagogie en ligne
                            </div>
                            <p className="text-base text-center py-4 leading-loose my-4 mx-4">
                                Nous mettons à la disposition de nos élèves un outil pédagogique connecté. En effet la plateforme ENPC Ediser permet à chaque élève de se connecter pour s'entrainer au Code de la route avant l'examen.
                            </p>
                        </div>
                    </a>
                </div>          
            </Cors>
        </div>
    )
}